/* NotificationPanel.css */
.notification-panel {
  width: 340px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 50px;
  right: 20px;
  z-index: 1000;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  opacity: 0; /* Initially hidden */
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.notification-panel.visible {
  opacity: 1; /* Show when visible */
  visibility: visible;
}

.notification-header {
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  font-weight: bold;
  color: #333;
}

.notification-header-actions {
  display: flex;
  gap: 10px;
}

.notification-header-actions button {
  background: none;
  border: none;
  color: #0073e6;
  cursor: pointer;
  font-size: 14px;
}

.notification-list {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 300px;
  overflow-y: auto;
}

.notification-item {
  padding: 12px 15px;
  border-bottom: 1px solid #f1f1f1;
  transition: background-color 0.3s;
  display: flex;
  align-items: flex-start;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-icon {
  margin-right: 10px;
}

.bell-icon {
  color: #333;
}

.notification-item.unread .bell-icon {
  color: #0073e6;
}

.notification-item.unread .notification-title {
  font-weight: 500;
  color: #333;
}

.notification-item:hover {
  background-color: #f5f5f5;
}

.notification-content {
  display: flex;
  flex-direction: column;
}

.notification-title {
  margin: 0;
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.notification-body {
  margin: 4px 0 0 0;
  font-size: 13px;
  color: #555;
}

.notification-actions {
  display: flex;
  gap: 10px;
  margin-top: 8px;
}

.notification-link {
  color: #0073e6;
  text-decoration: none;
  font-size: 13px;
}

.notification-link:hover {
  text-decoration: underline;
}

.dismiss-button {
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  font-size: 13px;
}

.dismiss-button:hover {
  color: #666;
}

.no-notifications {
  padding: 20px;
  text-align: center;
  color: #666;
}