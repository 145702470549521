/* src/components/BellIcon.css */
.bell-icon-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 10px;;
}

.bell-icon {
  font-size: 24px;
  color: #555;
}

.message-count {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
